import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { BiText } from "react-icons/bi";
import { Handle, Node, Position, useNodeId } from "reactflow";
import "../Editor.css";
import RunButton from "../components/RunButton";
import useFlowStore from "../Store";
import { isTargetTextHandle } from "../utils/edge";

export interface WebRequestNodeData {
  userInput: string;
  textOutput: string;
}

export default function WebRequestNode({ data }: { data: WebRequestNodeData }) {
  const nodeId = useNodeId();
  const [textValue, setTextValue] = useState<string>(data.userInput);
  const { nodes, setNodes } = useFlowStore();
  const [isError, setIsError] = useState(false);

  const { onProcessNode, isLoading } = useFlowStore();

  const onRun = () => {
    if (nodeId) {
      onProcessNode(nodeId);
    }
  };

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      try {
        const newUrl = new URL(input);
        setIsError(newUrl.protocol !== "http:" && newUrl.protocol !== "https:");
      } catch (err) {
        setIsError(true);
      }

      setTextValue(input);
    },
    [setIsError],
  );

  const onBlur = useCallback(() => {
    const updatedNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        const mappedNode = node as Node<WebRequestNodeData>;
        mappedNode.data.userInput = textValue;
      }
      return node;
    });
    setNodes(updatedNodes);
  }, [textValue, nodeId, nodes, setNodes]);

  return (
    <Box className="node text-node">
      <Flex justifyContent="space-between" paddingBottom="16px">
        <Flex fontSize="sm" alignItems="center">
          <BiText />
          <Text marginLeft="0.5">Web Request</Text>
        </Flex>
        <RunButton isLoading={isLoading} onClick={onRun} />
      </Flex>
      <FormControl isInvalid={isError}>
        <FormLabel>URL</FormLabel>
        <Input
          className="nodrag"
          placeholder="Enter a URL"
          type="email"
          value={textValue}
          onChange={onChange}
          onBlur={onBlur}
          variant="filled"
        />
        {!isError ? (
          <FormHelperText>Enter the url you'd like to fetch.</FormHelperText>
        ) : (
          <FormErrorMessage>URl is Invalid.</FormErrorMessage>
        )}
      </FormControl>
      <Handle
        className="text"
        type="source"
        position={Position.Bottom}
        id="text-output"
        isValidConnection={isTargetTextHandle}
      />
    </Box>
  );
}
