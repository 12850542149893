import { Tooltip, VStack } from "@chakra-ui/react";
import { animate } from "framer-motion";
import React, { createElement, useCallback } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { BiImage, BiText, BiWorld } from "react-icons/bi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import DragPreview from "./DragPreview";
import "./Sidebar.css";

export default function Sidebar() {
  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";

    const dragDiv = document.createElement("div");
    const node = createElement(DragPreview);
    dragDiv.innerHTML = renderToStaticMarkup(node);
    dragDiv.id = "drag";
    document.body.appendChild(dragDiv);
    event.dataTransfer.setDragImage(dragDiv, 0, 0);
  };

  const onDragEnd = () => {
    const dragDiv = document.getElementById("drag");
    if (dragDiv) {
      dragDiv.remove();
    }
  };

  const onTextOpen = useCallback(() => {
    animate(
      "#text-drawer",
      { opacity: 100, left: 24, visibility: "visible" },
      { duration: 0.5 },
    );
    animate("#drawer", { left: -90 }, { duration: 0.5 });
  }, []);

  const onImageOpen = useCallback(() => {
    animate(
      "#image-drawer",
      { opacity: 100, left: 24, visibility: "visible" },
      { duration: 0.5 },
    );
    animate("#drawer", { left: -48 }, { duration: 0.5 });
  }, []);

  const onOtherOpen = useCallback(() => {
    animate(
      "#other-drawer",
      { opacity: 100, left: 24, visibility: "visible" },
      { duration: 0.5 },
    );
    animate("#drawer", { left: -48 }, { duration: 0.5 });
  }, []);

  const onMenuClose = useCallback(() => {
    animate("#text-drawer", { opacity: 0, left: 90 }, { duration: 0.5 }).then(
      () => {
        animate("#text-drawer", { visibility: "hidden" }, { duration: 0 });
      },
    );

    animate("#image-drawer", { opacity: 0, left: 90 }, { duration: 0.5 }).then(
      () => {
        animate("#image-drawer", { visibility: "hidden" }, { duration: 0 });
      },
    );

    animate("#other-drawer", { opacity: 0, left: 90 }, { duration: 0.5 }).then(
      () => {
        animate("#other-drawer", { visibility: "hidden" }, { duration: 0 });
      },
    );
    animate("#drawer", { left: 24 }, { duration: 0.5 });
  }, []);

  return (
    <aside style={{ position: "absolute", top: "42%", bottom: "50%" }}>
      <div id="drawer" className="drawer">
        <VStack spacing={0}>
          <Tooltip
            placement="right"
            hasArrow
            label="Text Nodes"
            aria-label="Text Nodes"
          >
            <button
              className="menu-item"
              style={{ borderRadius: 0 }}
              onClick={onTextOpen}
            >
              <BiText size={28} />
            </button>
          </Tooltip>
          <Tooltip
            placement="right"
            hasArrow
            label="Image Nodes"
            aria-label="Image Nodes"
          >
            <button className="menu-item" onClick={onImageOpen}>
              <BiImage size={28} />
            </button>
          </Tooltip>
          <Tooltip
            placement="right"
            hasArrow
            label="Other Nodes"
            aria-label="Other Nodes"
          >
            <button className="menu-item" onClick={onOtherOpen}>
              <BiWorld size={28} />
            </button>
          </Tooltip>
        </VStack>
      </div>
      <div id="text-drawer" className="drawer">
        <Tooltip placement="right" hasArrow label="Back" aria-label="Back">
          <button className="menu-item back-button" onClick={onMenuClose}>
            <IoReturnUpBackOutline size={28} />
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          hasArrow
          label="Combines input text nodes, appends its own text, and outputs it"
        >
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "text")}
            onDragEnd={onDragEnd}
            draggable
          >
            Input
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          hasArrow
          label="Combines input text nodes, gets AI to answer, and outputs it"
        >
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "textPrompt")}
            onDragEnd={onDragEnd}
            draggable
          >
            Generate
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          hasArrow
          label="Combines input text and allows copy or download"
        >
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "textOutput")}
            onDragEnd={onDragEnd}
            draggable
          >
            Output
          </button>
        </Tooltip>
      </div>
      <div id="image-drawer" className="drawer">
        <Tooltip placement="right" hasArrow label="Back" aria-label="Back">
          <button className="menu-item back-button" onClick={onMenuClose}>
            <IoReturnUpBackOutline size={28} />
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          hasArrow
          label="Accepts an uploaded image and outputs it"
        >
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "imageInput")}
            onDragEnd={onDragEnd}
            draggable
          >
            Upload
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          hasArrow
          label="Combines input text and generates an image with AI"
        >
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "imagePrompt")}
            onDragEnd={onDragEnd}
            draggable
          >
            Generate
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          hasArrow
          label="Takes input image and text, then asks AI to modify the image given the text"
        >
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "imageToImage")}
            onDragEnd={onDragEnd}
            draggable
          >
            Modify
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          hasArrow
          label="Takes input image and text, then asks AI to answer the text given the image"
        >
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "imageToText")}
            onDragEnd={onDragEnd}
            draggable
          >
            Describe
          </button>
        </Tooltip>
      </div>
      <div id="other-drawer" className="drawer">
        <Tooltip placement="right" hasArrow label="Back" aria-label="Back">
          <button className="menu-item" onClick={onMenuClose}>
            <IoReturnUpBackOutline size={28} />
          </button>
        </Tooltip>
        <Tooltip placement="right" hasArrow label="Grabs data from a URL">
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "webRequest")}
            onDragEnd={onDragEnd}
            draggable
          >
            Web Request
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          hasArrow
          label="Extracts Text from the provided PDF"
        >
          <button
            className="menu-item sub-menu-item"
            onDragStart={(event) => onDragStart(event, "pdfToText")}
            onDragEnd={onDragEnd}
            draggable
          >
            PDF to Text
          </button>
        </Tooltip>
      </div>
    </aside>
  );
}
