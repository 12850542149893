import { Box, ChakraProvider, Flex, Text, Textarea } from "@chakra-ui/react";
import React from "react";
import { BiText } from "react-icons/bi";
import "./Editor.css";

export default function DragPreview() {
  return (
    <div
      style={{ maxWidth: "240px", maxHeight: "160px" }}
      className="react-flow__node"
    >
      <ChakraProvider>
        <Flex className="node" style={{ opacity: 0.4 }} height="160px">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BiText />
                <Text marginLeft="0.5">Drop Me</Text>
              </div>
            </div>
            <Textarea
              style={{ overflowY: "hidden" }}
              defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua."
              variant="filled"
            ></Textarea>
          </div>
        </Flex>
      </ChakraProvider>
    </div>
  );
}
