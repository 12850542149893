import { Box, Flex, Text, Textarea } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { BiText } from "react-icons/bi";
import { Handle, Node, NodeResizer, Position, useNodeId } from "reactflow";
import RunButton from "../components/RunButton";
import "../Editor.css";
import useFlowStore from "../Store";
import { isSourceTextHandle, isTargetTextHandle } from "../utils/edge";

export interface TextNodeData {
  textInput: string;
  userInput: string;
  textOutput: string;
}

export default function TextNode({ data }: { data: TextNodeData }) {
  const nodeId = useNodeId();
  const [textValue, setTextValue] = useState<string>(data.userInput);
  const { nodes, setNodes, onProcessNode, isLoading } = useFlowStore();

  const onRun = () => {
    if (nodeId) {
      onProcessNode(nodeId);
    }
  };

  const self = nodes.find((node) => node.id === nodeId);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const input = event.target.value;
      setTextValue(input);
    },
    [],
  );

  const onBlur = useCallback(() => {
    const updatedNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        const mappedNode = node as Node<TextNodeData>;
        mappedNode.data.userInput = textValue;
      }
      return node;
    });
    setNodes(updatedNodes);
  }, [textValue, nodeId, nodes, setNodes]);

  return (
    <>
      {self && self.selected && <NodeResizer minWidth={240} minHeight={120} />}
      <Flex flexDirection="column" className="node text-node">
        <Handle
          className="text"
          type="target"
          position={Position.Top}
          id="text-input"
          isValidConnection={isSourceTextHandle}
        />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Flex fontSize="sm" alignItems="center">
            <BiText />
            <Text marginLeft="0.5">Text Input</Text>
          </Flex>
          <RunButton isLoading={isLoading} onClick={onRun} />
        </Flex>
        <Textarea
          className="nodrag"
          placeholder="Add some text"
          value={textValue}
          onChange={onChange}
          onBlur={onBlur}
          variant="filled"
          height="100%"
        />
        <Text>{data.textInput}</Text>
        <Handle
          className="text"
          type="source"
          position={Position.Bottom}
          id="text-output"
          isValidConnection={isTargetTextHandle}
        />
      </Flex>
    </>
  );
}
