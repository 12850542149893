import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiImage } from "react-icons/bi";
import { MdUpload } from "react-icons/md";
import { Handle, Node, Position, useNodeId } from "reactflow";
import useFlowStore from "../Store";
import { isTargetImageHandle } from "../utils/edge";

export interface ImageInputNodeData {
  imageOutput: string;
}

export default function ImageInputNode({ data }: { data: ImageInputNodeData }) {
  const nodeId = useNodeId();
  const { nodes, id, setNodes } = useFlowStore();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const uploadFile = useCallback(
    async (file: File) => {
      const getUploadUrlRes = await axios.get<{
        url: string;
        id: string;
      }>(
        `${process.env.REACT_APP_API_URL}/document/${id}/${encodeURIComponent(
          file.name,
        )}`,
      );

      const fileBuffer = await file.arrayBuffer();
      await axios.put(getUploadUrlRes.data.url, fileBuffer, {
        headers: { "Content-Type": file.type },
      });
      const res = await axios.post<{ url: string }>(
        `${process.env.REACT_APP_API_URL}/document/${getUploadUrlRes.data.id}/complete`,
        {},
      );

      const updatedNodes = nodes.map((node) => {
        if (node.id === nodeId) {
          const mappedNode = node as Node<ImageInputNodeData>;
          mappedNode.data.imageOutput = res.data.url;
        }
        return node;
      });
      setNodes(updatedNodes);
      setIsUploading(false);
    },
    [setIsUploading, setNodes, nodeId, nodes, id],
  );

  const onUpload = useCallback(() => {
    if (inputRef) {
      inputRef.current!.click();
    }
  }, [inputRef]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIsUploading(true);
      uploadFile(e.target.files[0]);
    }
  };

  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (data.imageOutput && !isUploading) {
      axios.get(data.imageOutput).then((res) => {
        setImageUrl(res.data.url);
      });
    }
  }, [setImageUrl, data, isUploading]);

  return (
    <Box className="node text-node">
      <Flex flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex fontSize="sm" alignItems="center">
            <BiImage />
            <Text marginLeft="0.5">Upload Image</Text>
          </Flex>
        </Flex>
        <Box>
          <input
            style={{ visibility: "hidden", height: "0" }}
            id="file"
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
          />
          {imageUrl && <Image src={imageUrl} alt="uploaded image" />}
          <Button
            width="100%"
            marginTop="1rem"
            isLoading={isUploading}
            onClick={onUpload}
            size="sm"
            leftIcon={<MdUpload />}
            colorScheme="pink"
          >
            Upload
          </Button>
        </Box>
      </Flex>
      <Handle
        type="source"
        className="image"
        position={Position.Bottom}
        id="image-output"
        isValidConnection={isTargetImageHandle}
      />
    </Box>
  );
}
