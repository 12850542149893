import { Button, HStack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Footer(props: {}) {
  return (
    <footer
      style={{
        padding: "12px 24px",
        borderTop: "1px solid #D3D3D3",
        marginTop: "auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Text>© 2024 Bit3.ca</Text>
      <HStack spacing="10">
        <a rel="noreferrer" target="_blank" href="https://bit3.ca/#contact">
          <Button colorScheme="gray" variant="link">
            Contact Us
          </Button>
        </a>
        <Link to="/privacy">
          <Button colorScheme="gray" variant="link">
            Privacy Policy
          </Button>
        </Link>
        <Link to="/terms">
          <Button colorScheme="gray" variant="link">
            Terms of Service
          </Button>
        </Link>
      </HStack>
    </footer>
  );
}

export default Footer;
