import { create } from "zustand";

export const useAuthStore = create<{
  authData: string | null;
  setAuthData: (token: string) => void;
  clearAuthData(): void;
}>((set) => ({
  authData: localStorage.getItem("authentication")
    ? localStorage.getItem("authentication")
    : null,

  setAuthData: (token: string) => {
    localStorage.setItem("authentication", token);
    set({ authData: token });
  },

  clearAuthData() {
    localStorage.removeItem("authentication");
    set({ authData: null });
  },
}));
