import axios from "axios";
import { gzip } from "pako";

export function jwtInterceptor() {
  axios.interceptors.request.use((request) => {
    if (request.url && request.url.startsWith(process.env.REACT_APP_API_URL!)) {
      const jwtToken = localStorage.getItem("authentication");
      if (jwtToken) {
        request.headers.setAuthorization(`Bearer ${jwtToken}`);
      }

      //Gzip!
      if (
        request.data &&
        typeof request.data === "object" &&
        !FormData.prototype.isPrototypeOf(request.data)
      ) {
        try {
          const stringData = JSON.stringify(request.data);
          if (stringData.length > 1024) {
            request.data = gzip(stringData);
            request.headers["Content-Encoding"] = "gzip";
            request.headers["Content-Type"] = "application/json";
          }
        } catch (error) {
          console.error("Error compressing request data:", error);
        }
      }

      const apiKey = sessionStorage.getItem("apiKey");
      if (apiKey) {
        request.headers.set("temp-key", apiKey);
      }
    }
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = `${process.env.REACT_APP_API_URL}/auth`;
      }
      return Promise.reject(error);
    },
  );
}
