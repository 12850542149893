import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BiText } from "react-icons/bi";
import { Handle, NodeResizer, Position, useNodeId } from "reactflow";
import RunButton from "../components/RunButton";
import "../Editor.css";
import useFlowStore from "../Store";
import { isSourceTextHandle, isTargetTextHandle } from "../utils/edge";

export interface TextPromptNodeData {
  textOutput: string;
}

export default function TextPromptNode({ data }: { data: TextPromptNodeData }) {
  const nodeId = useNodeId();
  const { nodes, onProcessNode, isLoading } = useFlowStore();

  const self = nodes.find((node) => node.id === nodeId);

  const onRun = () => {
    if (nodeId) {
      onProcessNode(nodeId);
    }
  };

  return (
    <>
      {self && self.selected && <NodeResizer minWidth={240} minHeight={120} />}
      <Flex flexDirection="column" className="node text-node">
        <Handle
          className="text"
          type="target"
          position={Position.Top}
          id="text-input"
          isValidConnection={isSourceTextHandle}
        />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Flex fontSize="sm" alignItems="center">
            <BiText />
            <Text marginLeft="0.5">Generate Text</Text>
          </Flex>
          <RunButton isLoading={isLoading} onClick={onRun} />
        </Flex>
        <Box height="calc(100% - 16px)">
          {data.textOutput && (
            <Text
              textAlign="left"
              height="calc(100% - 16px)"
              resize="none"
              overflowY="auto"
              size="lg"
            >
              {data.textOutput}
            </Text>
          )}
          {!data.textOutput && (
            <Text height="calc(100% - 16px)" className="placeholder__text">
              Press run to generate text
            </Text>
          )}
        </Box>
        <Handle
          className="text"
          type="source"
          position={Position.Bottom}
          id="text-output"
          isValidConnection={isTargetTextHandle}
        />
      </Flex>
    </>
  );
}
