import { Box, Flex, Image, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiImage } from "react-icons/bi";
import { Handle, Position, useNodeId } from "reactflow";
import RunButton from "../components/RunButton";
import "../Editor.css";
import useFlowStore from "../Store";
import {
  isSourceImageHandle,
  isSourceTextHandle,
  isTargetImageHandle,
} from "../utils/edge";

export interface ImageToImageNodeData {
  textInput: string;
  imageInput: string;
  imageOutput: string;
}

export default function ImageToImageNode({
  data,
}: {
  data: ImageToImageNodeData;
}) {
  const nodeId = useNodeId();
  const { onProcessNode, isLoading } = useFlowStore();

  const onRun = () => {
    if (nodeId) {
      onProcessNode(nodeId);
    }
  };

  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (data.imageOutput) {
      axios.get(data.imageOutput).then((res) => {
        setImageUrl(res.data.url);
      });
    }
  }, [setImageUrl, data]);

  return (
    <Box className="node text-node">
      <Handle
        type="target"
        className="text"
        position={Position.Top}
        style={{ left: 60 }}
        id="text-input"
        isValidConnection={isSourceTextHandle}
      />
      <Handle
        type="target"
        className="image"
        position={Position.Top}
        style={{ left: 180 }}
        id="image-input"
        isValidConnection={isSourceImageHandle}
      />
      <Flex flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Flex fontSize="sm" alignItems="center">
            <BiImage />
            <Text marginLeft="0.5">Modify Image</Text>
          </Flex>
          <RunButton isLoading={isLoading} onClick={onRun} />
        </Flex>
        <Box>
          {data.imageOutput && imageUrl && (
            <Image src={imageUrl} alt="generated image" />
          )}
          {!data.imageOutput && (
            <Text className="placeholder__text">
              Press run to modify the image
            </Text>
          )}
        </Box>
      </Flex>
      <Handle
        type="source"
        className="image"
        position={Position.Bottom}
        id="image-output"
        isValidConnection={isTargetImageHandle}
      />
    </Box>
  );
}
