import axios from "axios";
import { Node, Edge } from "reactflow";

export interface Knit {
  name: string | null;
  id: string;
  edges: Edge[];
  nodes: Node[];
  isProcessing: boolean;
  progress: number;
}

export const KnitService = {
  async get(id: string) {
    const res = await axios.get<Knit>(
      `${process.env.REACT_APP_API_URL}/knit/${id}`,
    );
    return res.data;
  },

  async save(knit: Partial<Knit>) {
    const res = await axios.post<Knit>(
      `${process.env.REACT_APP_API_URL}/knit/${knit.id}`,
      {
        nodes: knit.nodes,
        edges: knit.edges,
        name: knit.name,
      },
    );
    return res.data;
  },

  async process(id: string, isFresh: boolean) {
    const res = await axios.post<Knit>(
      `${process.env.REACT_APP_API_URL}/knit/${id}/process`,
      {
        isFresh,
      },
    );
    return res.data;
  },

  async processNode(id: string, nodeId: string) {
    const res = await axios.post<Knit>(
      `${process.env.REACT_APP_API_URL}/knit/${id}/process/${nodeId}`,
    );
    return res.data;
  },

  async list() {
    const res = await axios.get<Knit[]>(
      `${process.env.REACT_APP_API_URL}/knit`,
    );
    return res.data;
  },

  async delete(id: string) {
    const res = await axios.delete<void>(
      `${process.env.REACT_APP_API_URL}/knit/${id}`,
    );
    return res.data;
  },

  async duplicate(id: string) {
    const res = await axios.post<void>(
      `${process.env.REACT_APP_API_URL}/knit/${id}/duplicate`,
    );
    return res.data;
  },
};
