import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Progress,
} from "@chakra-ui/react";
import { Node } from "@reactflow/core/dist/esm/types";
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { IoPlay } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  ReactFlowInstance,
  ReactFlowProvider,
} from "reactflow";

import "reactflow/dist/style.css";
import { v4 } from "uuid";
import { shallow } from "zustand/shallow";
import ImageInputNode from "./nodes/ImageInputNode";
import ImagePromptNode from "./nodes/ImagePromptNode";
import ImageToImageNode from "./nodes/ImageToImageNode";
import ImageToTextNode from "./nodes/ImageToTextNode";
import PdfToTextNode from "./nodes/PdfToTextNode";
import TextNode from "./nodes/TextNode";
import TextOutputNode from "./nodes/TextOutputNode";
import TextPromptNode from "./nodes/TextPromptNode";
import WebRequestNode from "./nodes/WebRequestNode";
import Sidebar from "./Sidebar";
import useFlowStore, { ReactFlowState } from "./Store";

const selector = (state: ReactFlowState) => state;

export default function EditorPage() {
  const { id } = useParams();
  const knitId = id ?? v4();

  const nodeTypes = useMemo(
    () => ({
      textPrompt: TextPromptNode,
      textOutput: TextOutputNode,
      text: TextNode,
      imagePrompt: ImagePromptNode,
      webRequest: WebRequestNode,
      imageInput: ImageInputNode,
      imageToImage: ImageToImageNode,
      imageToText: ImageToTextNode,
      pdfToText: PdfToTextNode,
    }),
    [],
  );
  const reactFlowWrapper = useRef<any>(null);

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null);

  const {
    nodes,
    edges,
    name,
    progress,
    setName,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onProcess,
    setNodes,
    setEdges,
    loadKnit,
    onSave,
    isLoading,
  } = useFlowStore(selector, shallow);

  useEffect(() => {
    loadKnit(knitId);
  }, [loadKnit, knitId]);

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onMoveEnd = () => {
    if (reactFlowInstance) {
      onSave();
    }
  };

  const [tempName, setTempName] = useState(name);

  useEffect(() => {
    setTempName(name);
  }, [name, setTempName]);

  const onNameChange = useCallback(
    (newName: string) => {
      setTempName(newName);
    },
    [setTempName],
  );

  const onNameSubmit = useCallback(
    (newName: string) => {
      setName(newName);
    },
    [setName],
  );

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper!.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      if (!type) {
        return;
      }

      const position = reactFlowInstance!.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode: Node = {
        id: v4(),
        type,
        position,
        data: { label: `${type} node` },
        style: type === "textPrompt" ? { height: "300px" } : undefined,
      };

      const updatedNodes = nodes.concat(newNode);
      setNodes(updatedNodes);
    },
    [reactFlowInstance, setNodes, nodes],
  );

  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge),
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            })),
          );

          return [...remainingEdges, ...createdEdges];
        }, edges),
      );
    },
    [nodes, edges, setEdges],
  );

  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [nodeIdsToCopy, setNodeIdsToCopy] = useState<string[]>([]);

  const onSelectionChange = useCallback(
    ({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) => {
      setSelectedNodes(nodes.map((node) => node.id));
    },
    [setSelectedNodes],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "c") {
        setNodeIdsToCopy(selectedNodes);
      }
      if (e.metaKey && e.key === "v") {
        const nodesToCopy = nodes.filter((node) =>
          nodeIdsToCopy.includes(node.id),
        );
        const copiedNodes = nodesToCopy.map((node) => ({
          ...node,
          id: v4(),
          selected: false,
          position: {
            x: node.position.x + (node.width ?? 200) + 50,
            y: node.position.y,
          },
          width: node.width,
          height: node.height,
          zIndex: (node.zIndex ?? 0) + 1,
          data: {
            ...node.data,
          },
        }));
        setNodes([...nodes, ...copiedNodes]);
      }
    },
    [nodeIdsToCopy, nodes, selectedNodes, setNodeIdsToCopy, setNodes],
  );

  return (
    <>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 24px",
          borderBottom: "1px solid #D3D3D3",
          alignItems: "center",
        }}
      >
        <Link to="/home">
          <img src="/logo/full.svg" alt="Prompt Knit" />
        </Link>
        <Breadcrumb
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          spacing="8px"
          separator={<HiOutlineChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/home">My Knits</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="/editor">
              <Editable
                onChange={onNameChange}
                onSubmit={onNameSubmit}
                value={tempName ?? undefined}
                placeholder="untitled"
                style={{ fontWeight: "bold" }}
              >
                <EditablePreview />
                <EditableInput />
              </Editable>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        {isLoading && (
          <Progress
            borderRadius={3}
            max={100}
            width="30%"
            colorScheme="purple"
            value={progress}
            hasStripe
          />
        )}
        {!isLoading && (
          <Box>
            <Button
              isLoading={isLoading}
              onClick={() => {
                onProcess(true);
              }}
              leftIcon={<IoPlay />}
              variant="solid"
            >
              Run
            </Button>
          </Box>
        )}
      </header>
      <div style={{ width: "100%", height: "calc(100% - 57px)" }}>
        <ReactFlowProvider>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#FDFDFD",
            }}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodesDraggable={true}
              nodeTypes={nodeTypes}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onSelectionChange={onSelectionChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              nodeDragThreshold={6}
              onNodeDragStop={onMoveEnd}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onNodesDelete={onNodesDelete}
              onKeyDown={onKeyDown}
              fitView
              snapToGrid={true}
              snapGrid={[20, 20]}
            >
              <Background
                id="2"
                gap={20}
                color="#D2D6DB"
                size={2}
                variant={BackgroundVariant.Dots}
              />
              <Controls position="bottom-right" />
            </ReactFlow>
          </div>
          <Sidebar />
        </ReactFlowProvider>
      </div>
    </>
  );
}
