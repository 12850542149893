import { Box } from "@chakra-ui/react";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import KnitList from "./editor/components/KnitList";

export default function HomePage() {
  return (
    <>
      <Header title="My Knits" />
      <Box padding="12">
        <KnitList />
      </Box>
      <Footer />
    </>
  );
}
