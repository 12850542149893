import { Box, Button, Flex, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useCallback, useRef, useState } from "react";
import { BiImage } from "react-icons/bi";
import { MdUpload } from "react-icons/md";
import { Handle, Node, Position, useNodeId } from "reactflow";
import RunButton from "../components/RunButton";
import useFlowStore from "../Store";
import { isTargetTextHandle } from "../utils/edge";

export interface PdfToTextNodeData {
  pdfUrl?: string;
  fileName?: string;
}

export default function PdfToTextNode({ data }: { data: PdfToTextNodeData }) {
  const nodeId = useNodeId();
  const { nodes, id, setNodes } = useFlowStore();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { onProcessNode, isLoading } = useFlowStore();

  const onRun = () => {
    if (nodeId) {
      onProcessNode(nodeId);
    }
  };

  const uploadFile = useCallback(
    async (file: File) => {
      const getUploadUrlRes = await axios.get<{
        url: string;
        id: string;
      }>(
        `${process.env.REACT_APP_API_URL}/document/${id}/${encodeURIComponent(
          file.name,
        )}`,
      );

      const fileBuffer = await file.arrayBuffer();
      await axios.put(getUploadUrlRes.data.url, fileBuffer, {
        headers: { "Content-Type": file.type },
      });
      const res = await axios.post<{ url: string }>(
        `${process.env.REACT_APP_API_URL}/document/${getUploadUrlRes.data.id}/complete`,
        {},
      );

      const updatedNodes = nodes.map((node) => {
        if (node.id === nodeId) {
          const mappedNode = node as Node<PdfToTextNodeData>;
          mappedNode.data.pdfUrl = res.data.url;
          mappedNode.data.fileName = file.name;
        }
        return node;
      });
      setNodes(updatedNodes);
      setIsUploading(false);
    },
    [setIsUploading, setNodes, nodeId, nodes, id],
  );

  const onUpload = useCallback(() => {
    if (inputRef) {
      inputRef.current!.click();
    }
  }, [inputRef]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIsUploading(true);
      uploadFile(e.target.files[0]);
    }
  };

  return (
    <Box className="node text-node">
      <Flex flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex fontSize="sm" alignItems="center">
            <BiImage />
            <Text marginLeft="0.5">PDF To Text</Text>
          </Flex>
          <RunButton isLoading={isLoading} onClick={onRun} />
        </Flex>
        <Box>
          <input
            style={{ visibility: "hidden", height: "0" }}
            id="file"
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
          />
          {data.pdfUrl && (
            <a target="_blank" rel="noreferrer" href={data.pdfUrl}>
              {data.fileName ?? "Download File"}
            </a>
          )}
          <Button
            width="100%"
            marginTop="1rem"
            isLoading={isUploading}
            onClick={onUpload}
            size="sm"
            leftIcon={<MdUpload />}
          >
            Upload
          </Button>
        </Box>
      </Flex>
      <Handle
        type="source"
        className="text"
        position={Position.Bottom}
        id="text-output"
        isValidConnection={isTargetTextHandle}
      />
    </Box>
  );
}
