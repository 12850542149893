import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import ApiKeyPage from "./pages/ApiKey";
import EditorPage from "./pages/editor/Editor";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import NotFoundPage from "./pages/Not-Found";
import "@fontsource/inter/700.css";
import "@fontsource/inter/400.css";
import PrivacyPage from "./pages/Privacy";
import TermsPage from "./pages/Terms";

function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <NotFoundPage />,
      children: [
        {
          path: "/",
          element: <LoginPage />,
        },
        {
          path: "/apiKey",
          element: <ApiKeyPage />,
        },
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: "/privacy",
          element: <PrivacyPage />,
        },
        {
          path: "/terms",
          element: <TermsPage />,
        },
        {
          path: "/edit/:id",
          element: <EditorPage />,
        },
      ],
    },
  ]);

  const customTheme = extendTheme(
    {
      fonts: {
        heading: "Inter",
        body: "Inter",
      },
    },
    withDefaultColorScheme({ colorScheme: "purple" }),
  );

  return (
    <ChakraProvider theme={customTheme}>
      <div className="App">
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </div>
    </ChakraProvider>
  );
}

export default App;
