import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiCopy, BiDotsVerticalRounded, BiPlus, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import ReactFlow, { Background, BackgroundVariant } from "reactflow";
import { v4 } from "uuid";
import { Knit, KnitService } from "../../../services/Knit.service";
import ImageInputNode from "../nodes/ImageInputNode";
import ImagePromptNode from "../nodes/ImagePromptNode";
import ImageToImageNode from "../nodes/ImageToImageNode";
import ImageToTextNode from "../nodes/ImageToTextNode";
import TextNode from "../nodes/TextNode";
import TextOutputNode from "../nodes/TextOutputNode";
import TextPromptNode from "../nodes/TextPromptNode";
import WebRequestNode from "../nodes/WebRequestNode";

export default function KnitList({ onClick }: { onClick?: () => void }) {
  const [knits, setKnits] = useState<Knit[]>([]);
  const [id] = useState(v4());
  const [selectedKnit, setSelectedKnit] = useState<string | null>(null);

  const nodeTypes = useMemo(
    () => ({
      text: TextNode,
      textPrompt: TextPromptNode,
      textOutput: TextOutputNode,
      imagePrompt: ImagePromptNode,
      webRequest: WebRequestNode,
      imageInput: ImageInputNode,
      imageToImage: ImageToImageNode,
      imageToText: ImageToTextNode,
    }),
    [],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    KnitService.list().then((knits) => {
      setKnits(knits);
    });
  }, [setKnits]);

  const onDelete = (knitId: string) => {
    setSelectedKnit(knitId);
    onOpen();
  };

  const onConfirmDelete = useCallback(() => {
    if (selectedKnit) {
      KnitService.delete(selectedKnit).then(() => {
        onClose();
        KnitService.list().then((knits) => {
          setKnits(knits);
        });
      });
    }
  }, [selectedKnit, onClose]);

  const onDuplicate = useCallback((knitId: string) => {
    KnitService.duplicate(knitId).then(() => {
      KnitService.list().then((knits) => {
        setKnits(knits);
      });
    });
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(auto-fit, minmax(330px, 1fr))" gap={6}>
        <Link to={`/edit/${id}`}>
          <Container
            style={{ border: "1px solid #E2E8F0", background: "#CBD5E0" }}
            _hover={{ boxShadow: "var(--chakra-shadows-lg)" }}
            boxShadow="base"
            height="255px"
            size="sm"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <BiPlus color="#FFF" size="50%" />
          </Container>
        </Link>
        {knits.map((knit) => (
          <Link key={knit.id} to={`/edit/${knit.id}`}>
            <Container
              _hover={{ boxShadow: "var(--chakra-shadows-lg)" }}
              display="flex"
              flexDirection="column"
              boxShadow="base"
              height="255px"
              size="sm"
              padding={0}
            >
              <Box display="flex" justifyContent="space-between">
                <Box
                  padding={3}
                  display="flex"
                  alignItems="center"
                  textAlign="left"
                >
                  {knit.name || "untitled"}
                </Box>
                <Box
                  padding={1}
                  onClick={(e) => e.preventDefault()}
                  textAlign="left"
                >
                  <Menu colorScheme="gray">
                    <MenuButton
                      variant="ghost"
                      colorScheme="gray"
                      as={IconButton}
                      aria-label="Options"
                      icon={<BiDotsVerticalRounded />}
                    ></MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          onDuplicate(knit.id);
                        }}
                        icon={<BiCopy />}
                      >
                        Duplicate
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          onDelete(knit.id);
                        }}
                        icon={<BiTrash />}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>

              <Box
                pointerEvents="none"
                cursor="pointer !important"
                height="100%"
                backgroundColor="#F7FAFC"
              >
                <ReactFlow
                  fitView={true}
                  nodeTypes={nodeTypes}
                  nodes={knit.nodes}
                  edges={knit.edges}
                  preventScrolling={true}
                >
                  <Background
                    id="2"
                    gap={20}
                    color="#D2D6DB"
                    size={2}
                    variant={BackgroundVariant.Dots}
                  />
                </ReactFlow>
              </Box>
            </Container>
          </Link>
        ))}
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Knit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the selected Knit?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onConfirmDelete}>
              Delete
            </Button>
            <Button colorScheme="red" variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
