import { Box, Flex, Image, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiImage } from "react-icons/bi";
import { Handle, Position, useNodeId } from "reactflow";
import RunButton from "../components/RunButton";
import "../Editor.css";
import useFlowStore from "../Store";
import { isSourceTextHandle, isTargetImageHandle } from "../utils/edge";

export interface ImagePromptNodeData {
  textInput: string;
  imageOutput: string;
}

export default function ImagePromptNode({
  data,
}: {
  data: ImagePromptNodeData;
}) {
  const nodeId = useNodeId();
  const { onProcessNode, isLoading } = useFlowStore();

  const onRun = () => {
    if (nodeId) {
      onProcessNode(nodeId);
    }
  };

  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (data.imageOutput) {
      axios.get(data.imageOutput).then((res) => {
        setImageUrl(res.data.url);
      });
    }
  }, [setImageUrl, data.imageOutput]);

  return (
    <Box className="node text-node">
      <Handle
        type="target"
        className="text"
        position={Position.Top}
        id="text-input"
        isValidConnection={isSourceTextHandle}
      />
      <Flex flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Flex fontSize="sm" alignItems="center">
            <BiImage />
            <Text marginLeft="0.5">Generate Image</Text>
          </Flex>
          <RunButton isLoading={isLoading} onClick={onRun} />
        </Flex>
        <Box>
          {data.imageOutput && imageUrl && (
            <Image src={imageUrl} alt="generated image" />
          )}
          {!data.imageOutput && (
            <Text className="placeholder__text">
              Press run to generate an image
            </Text>
          )}
        </Box>
      </Flex>
      <Handle
        type="source"
        className="image"
        position={Position.Bottom}
        id="image-output"
        isValidConnection={isTargetImageHandle}
      />
    </Box>
  );
}
