import { Box, Button, Flex, Text, Textarea, useToast } from "@chakra-ui/react";
import React from "react";
import { BiCopy, BiDownload, BiText } from "react-icons/bi";
import { Handle, NodeResizer, Position, useNodeId } from "reactflow";
import RunButton from "../components/RunButton";
import "../Editor.css";
import useFlowStore from "../Store";
import { isSourceTextHandle } from "../utils/edge";

export interface TextOutputNodeData {
  textOutput: string;
}

export default function TextOutputNode({ data }: { data: TextOutputNodeData }) {
  const nodeId = useNodeId();
  const toast = useToast();
  const { nodes, onProcessNode, isLoading } = useFlowStore();

  const onRun = () => {
    if (nodeId) {
      onProcessNode(nodeId);
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(data.textOutput).then(() => {
      toast({
        status: "success",
        description: "Copied to clipboard",
      });
    });
  };

  const self = nodes.find((node) => node.id === nodeId);

  const onDownload = () => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(data.textOutput),
    );
    element.setAttribute("download", "Knit.txt");
    element.style.display = "none";

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      {self && self.selected && <NodeResizer minWidth={240} minHeight={120} />}
      <Flex flexDirection="column" className="node text-node">
        <Handle
          type="target"
          className="text"
          position={Position.Top}
          id="text-input"
          isValidConnection={isSourceTextHandle}
        />
        <Flex justifyContent="space-between" paddingBottom="16px">
          <Flex fontSize="sm" alignItems="center">
            <BiText />
            <Text marginLeft="0.5">Text Output</Text>
          </Flex>
          <RunButton isLoading={isLoading} onClick={onRun} />
        </Flex>
        {data.textOutput && (
          <Textarea
            height="calc(100% - 16px)"
            readOnly={true}
            className="nodrag"
            resize="none"
            minHeight="125px"
            marginBottom={4}
            fontSize="lg"
            value={data.textOutput}
          />
        )}
        {!data.textOutput && (
          <Text
            height="calc(100% - 16px)"
            marginBottom={4}
            color="gray.500"
            fontSize="md"
          >
            Output text will go here
          </Text>
        )}
        <Flex justifyContent="space-between">
          <Button
            variant="solid"
            colorScheme="gray"
            onClick={onCopy}
            leftIcon={<BiCopy />}
            size="xs"
            isDisabled={!data.textOutput}
          >
            Copy
          </Button>
          <Button
            variant="solid"
            colorScheme="gray"
            onClick={onDownload}
            leftIcon={<BiDownload />}
            size="xs"
            isDisabled={!data.textOutput}
          >
            Download
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
