import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BiImage } from "react-icons/bi";
import { Handle, Position, useNodeId } from "reactflow";
import RunButton from "../components/RunButton";
import "../Editor.css";
import useFlowStore from "../Store";
import {
  isSourceImageHandle,
  isSourceTextHandle,
  isTargetImageHandle,
} from "../utils/edge";

export interface ImageToTextNodeData {
  textInput: string;
  imageInput: string;
  textOutput: string;
}

export default function ImageToTextNode({
  data,
}: {
  data: ImageToTextNodeData;
}) {
  const nodeId = useNodeId();
  const { onProcessNode, isLoading } = useFlowStore();

  const onRun = () => {
    if (nodeId) {
      onProcessNode(nodeId);
    }
  };

  return (
    <Box className="node text-node">
      <Handle
        type="target"
        className="text"
        position={Position.Top}
        style={{ left: 60 }}
        id="text-input"
        isValidConnection={isSourceTextHandle}
      />
      <Handle
        type="target"
        className="image"
        position={Position.Top}
        style={{ left: 180 }}
        id="image-input"
        isValidConnection={isSourceImageHandle}
      />
      <Flex flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Flex fontSize="sm" alignItems="center">
            <BiImage />
            <Text marginLeft="0.5">Describe an Image</Text>
          </Flex>
          <RunButton isLoading={isLoading} onClick={onRun} />
        </Flex>
        <Box>
          {data.textOutput && (
            <Text maxHeight="400px" overflowY="auto" size="lg">
              {data.textOutput}
            </Text>
          )}
          {!data.textOutput && (
            <Text className="placeholder__text">
              Press run to describe the image
            </Text>
          )}
        </Box>
      </Flex>
      <Handle
        type="source"
        className="image"
        position={Position.Bottom}
        id="image-output"
        isValidConnection={isTargetImageHandle}
      />
    </Box>
  );
}
