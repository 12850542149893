import { Button } from "@chakra-ui/react";
import { IoPlaySharp } from "react-icons/io5";

export default function RunButton({
  onClick,
  isLoading,
}: {
  onClick?: () => void;
  isLoading: boolean;
}) {
  return (
    <Button
      isLoading={isLoading}
      onClick={onClick}
      variant="outline"
      size="xs"
      leftIcon={<IoPlaySharp />}
    >
      Run
    </Button>
  );
}
