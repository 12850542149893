import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { IoAdd, IoChevronDown } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { useAuthStore } from "../state/auth";

function Header(props: { title?: string }) {
  const { title = "" } = props;
  const newId = v4();
  const store = useAuthStore((state) => state);
  const navigate = useNavigate();
  const isLoggedIn = !!store.authData;

  const onLogout = () => {
    store.clearAuthData();
    navigate("/");
  };

  let controls = (
    <>
      <Link to="/">
        <Box>
          <img src="/logo/full.svg" alt="Prompt Knit" />
        </Box>
      </Link>
      <Heading size="md">{title}</Heading>
      <Box></Box>
    </>
  );
  if (isLoggedIn) {
    controls = (
      <>
        <Link to="/home">
          <img src="/logo/full.svg" alt="Prompt Knit" />
        </Link>
        <Heading
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {title}
        </Heading>
        <Flex alignItems="center">
          <Link to={`/edit/${newId}`}>
            <Button marginRight="4" leftIcon={<IoAdd />}>
              Knit
            </Button>
          </Link>
          <Menu>
            <MenuButton as={Avatar}></MenuButton>
            <MenuList>
              <Link to={`/apiKey`}>
                <MenuItem>API Key</MenuItem>
              </Link>
              <MenuItem onClick={onLogout}>Log Out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </>
    );
  }

  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "8px 24px",
        borderBottom: "1px solid #D3D3D3",
        alignItems: "center",
      }}
    >
      {controls}
    </header>
  );
}

export default Header;
