import { Box, Button, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useAuthStore } from "../state/auth";

export default function LoginPage() {
  const setAuthData = useAuthStore((state) => state.setAuthData);
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const needsKey = params.get("needsKey");

  useEffect(() => {
    if (token) {
      setAuthData(token);
      if (needsKey) {
        navigate("/apiKey");
      } else {
        navigate("/home");
      }
    }
  }, [token, setAuthData, navigate, needsKey]);

  const onLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth`;
  };

  return (
    <>
      <Header />
      <Flex
        margin="7rem"
        marginTop="5rem"
        flexDirection="column"
        justifyContent="center"
      >
        <Heading>
          PromptKnit is a drag and drop interface for interacting with AI
        </Heading>
        <Grid
          marginTop={5}
          templateColumns="repeat(auto-fit, minmax(330px, 1fr))"
          gap={6}
        >
          <Box minWidth="300px" flexDirection="column" alignItems="center">
            <Flex alignItems="center" height="300px">
              <video autoPlay muted loop>
                <source src="/media/Clip1.webm" type="video/mp4" />
              </video>
            </Flex>
            <Text marginTop={2} fontSize="xl">
              Drag & drop nodes to gather data
            </Text>
          </Box>
          <Box>
            <Flex alignItems="center" height="300px">
              <video autoPlay muted loop>
                <source src="/media/Clip2.webm" type="video/mp4" />
              </video>
            </Flex>
            <Text marginTop={1} fontSize="xl">
              Connect nodes to pass data around
            </Text>
          </Box>
          <Box>
            <Flex alignItems="center" height="300px">
              <video autoPlay muted loop>
                <source src="/media/Clip3.webm" type="video/mp4" />
              </video>
            </Flex>
            <Text marginTop={1} fontSize="xl">
              Create an AI graph that can easily be re-used
            </Text>
          </Box>
        </Grid>
        <Box marginTop={8}>
          Let's get started:
          <br />
          <Button marginTop={1} onClick={onLogin}>
            Login with Google
          </Button>
        </Box>
      </Flex>
      <Footer />
    </>
  );
}
