import { Connection } from "reactflow";

export const isTargetTextHandle = (connection: Connection) => {
  return !!connection.targetHandle?.includes("text");
};

export const isSourceTextHandle = (connection: Connection) => {
  return !!connection.sourceHandle?.includes("text");
};

export const isTargetImageHandle = (connection: Connection) => {
  return !!connection.targetHandle?.includes("image");
};

export const isSourceImageHandle = (connection: Connection) => {
  return !!connection.sourceHandle?.includes("image");
};
