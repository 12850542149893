import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from "reactflow";
import { createWithEqualityFn } from "zustand/traditional";
import { KnitService } from "../../services/Knit.service";

export type ReactFlowState = {
  id: string;
  name: string | null;
  isLoading: boolean;
  setName: (name: string) => void;
  loadKnit: (id: string) => void;
  nodes: Node[];
  edges: Edge[];
  progress: number;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  setNodes: (nodes: Node[]) => void;
  setEdges: (nodes: Edge[]) => void;
  editNode: (nodeId: string, data: any) => void;
  onSave: () => void;
  onProcess: (isFresh?: boolean) => void;
  onProcessNode: (nodeId: string) => void;
};

const useFlowStore = createWithEqualityFn<ReactFlowState>((set, get) => ({
  id: "",
  name: null,
  isLoading: false,
  loadKnit: (id: string) => {
    set({
      isLoading: true,
    });

    KnitService.get(id).then((value) => {
      set({
        id: value.id,
        nodes: value.nodes,
        edges: value.edges,
        name: value.name,
        isLoading: false,
        progress: value.progress,
      });
    });
  },
  nodes: [],
  edges: [],
  progress: 100,
  setNodes: (nodes: Node[]) => {
    set({
      nodes,
    });
    get().onSave();
  },
  setName: (name: string) => {
    set({
      name,
    });
    get().onSave();
  },
  setEdges: (edges: Edge[]) => {
    set({
      edges,
    });
    get().onSave();
  },
  editNode: (nodeId: string, data: any) => {
    const updatedNodes = get().nodes.map((node) => {
      if (node.id === nodeId) {
        node.data = data;
      }
      return node;
    });
    set({
      nodes: updatedNodes,
    });
    get().onSave();
  },
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
    get().onSave();
  },
  onConnect: (connection: Connection) => {
    const newEdge = addEdge(connection, [])[0];
    if (connection.sourceHandle && connection.sourceHandle.includes("text")) {
      newEdge.className = "text";
    }
    if (connection.sourceHandle && connection.sourceHandle.includes("image")) {
      newEdge.className = "image";
    }
    const updatedEdges = addEdge(newEdge, get().edges);
    set({
      edges: updatedEdges,
    });
    get().onSave();
  },
  onSave: () => {
    KnitService.save({
      id: get().id,
      name: get().name,
      edges: get().edges,
      nodes: get().nodes,
    });
  },
  onProcess: (isNewCall = true) => {
    set({
      isLoading: true,
    });

    KnitService.process(get().id, isNewCall).then((result) => {
      set({
        nodes: result.nodes,
        edges: result.edges,
        isLoading: result.isProcessing,
        progress: result.progress,
      });
      if (result.isProcessing) {
        setTimeout(() => {
          get().onProcess(false);
        }, 2000);
      }
    });
  },
  onProcessNode: (nodeId: string) => {
    set({
      isLoading: true,
    });

    KnitService.processNode(get().id, nodeId).then((result) => {
      set({
        nodes: result.nodes,
        edges: result.edges,
        isLoading: false,
      });
    });
  },
}));

export default useFlowStore;
