import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Knit } from "../services/Knit.service";

export default function ApiKeyPage() {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(true);
  const [textValue, setTextValue] = useState<string>("");
  const [isChecked, setChecked] = useState<boolean>(false);

  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      setTextValue(input);
      setIsError(!input);
    },
    [setTextValue],
  );

  const onChangeCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.checked;
      setChecked(input);
    },
    [setChecked],
  );

  const saveApiKey = React.useCallback(async (apiKey: string) => {
    await axios.post<Knit>(`${process.env.REACT_APP_API_URL}/auth/setKey`, {
      apiKey,
    });
  }, []);

  const onSubmit = useCallback(() => {
    //Call API to Set API Key or set to Local Store
    if (isChecked) {
      saveApiKey(textValue).then((r) => {
        navigate("/home");
      });
    } else {
      sessionStorage.setItem("apiKey", textValue);
      navigate("/home");
    }
  }, [textValue, isChecked, navigate, saveApiKey]);

  return (
    <>
      <Header />
      <Box margin="6" display="flex" justifyContent="left">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="left"
          minWidth="400px"
          width="40%"
        >
          <FormControl isInvalid={isError}>
            <FormLabel>OpenAPI API Key</FormLabel>
            <Input type="password" value={textValue} onChange={onChangeText} />
            {isError && (
              <FormErrorMessage>OpenAPI Key is required</FormErrorMessage>
            )}
          </FormControl>
          <Checkbox marginTop="1" checked={isChecked} onChange={onChangeCheck}>
            Save API Key for Next Login
          </Checkbox>
          <Button onClick={onSubmit} marginTop="2" isDisabled={isError}>
            Save and Continue
          </Button>
          <Text color={"gray.600"} marginTop="3">
            In the Beta Phase, we will use your API Key for all OpenAI requests.
            This keeps Prompt Knit free.
          </Text>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
